import React, { useEffect } from "react"
import { Box } from "@chakra-ui/react"

const QuoteForm = () => {
  const addStyle = url => {
    const link = document.createElement("link")
    link.async = true
    link.href = url
    link.media = "screen"
    link.rel = "stylesheet"
    document.body.appendChild(link)
  }

  const addExternalScript = url => {
    const script = document.createElement("script")
    script.src = url
    script.async = true
    script.setAttribute("clienthub_id", "f77fe403-16e0-4787-9bea-bc2c98399cd9")
    script.setAttribute(
      "form_url",
      "https://clienthub.getjobber.com/client_hubs/f77fe403-16e0-4787-9bea-bc2c98399cd9/public/work_request/embedded_work_request_form"
    )
    document.body.appendChild(script)
  }

  useEffect(() => {
    addStyle(
      "https://d3ey4dbjkt2f6s.cloudfront.net/assets/external/work_request_embed.css"
    )
    addExternalScript(
      "https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_snippet.js"
    )
  }, [])

  return (
    <Box
      maxW="xl"
      mx="auto"
      px={{ base: "6", lg: "8" }}
      py={{ base: "16", lg: "0" }}
      textAlign="center"
    >
      <div id="f77fe403-16e0-4787-9bea-bc2c98399cd9"></div>
    </Box>
  )
}

export default QuoteForm
