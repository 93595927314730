import React from "react"
import {
  Box,
  Heading,
  Stack,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import { BsArrowReturnRight } from "react-icons/bs"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import QuoteForm from "../components/QuoteForm"

const RequestQuote = ({ data }) => {
  return (
    <Layout>
      <Meta
        title="Request a Quote"
        description="Request a Sioux Falls Lawn Care quote from Bullseye Lawn Care. If you need help with your lawn, please request a quote and we will contact you soon."
      />
      <Box
        as="section"
        bg={useColorModeValue("gray.100", "gray.600")}
        pt="16"
        pb="24"
      >
        <Box
          maxW={{ base: "xl", md: "7xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          <Stack
            direction={{ base: "column", lg: "row" }}
            spacing={{ base: "3rem", lg: "2rem" }}
            align={{ lg: "start" }}
            justify="space-between"
          >
            <Box flex="1" mt={{ base: "4", lg: "16" }} maxW={{ lg: "520px" }}>
              <Text
                size="xs"
                textTransform="uppercase"
                fontWeight="semibold"
                color={useColorModeValue(
                  "bullseyeOrange.500",
                  "bullseyeOrange.400"
                )}
                letterSpacing="wide"
              >
                Ready to make your neighbors jealous?
              </Text>
              <Heading
                as="h1"
                size="3xl"
                color={useColorModeValue(
                  "bullseyeGreen.600",
                  "bullseyeGreen.400"
                )}
                mt="8"
                fontWeight="extrabold"
                letterSpacing="tight"
              >
                Get a completely free quote
              </Heading>
              <Text
                color={useColorModeValue("gray.600", "gray.400")}
                mt="4"
                fontSize="lg"
                fontWeight="medium"
              >
                Do you want to get your property looking its best? Get started
                with a hassle-free quote.
              </Text>
              <Box
                textAlign="right"
                mt={8}
                display={{ base: "none", lg: "block" }}
              >
                <Icon
                  fontSize="128px"
                  color={useColorModeValue(
                    "bullseyeOrange.500",
                    "bullseyeOrange.400"
                  )}
                  as={BsArrowReturnRight}
                />
              </Box>
            </Box>
            <Box pos="relative" w={{ base: "full", lg: "560px" }}>
              <QuoteForm />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Layout>
  )
}

export default RequestQuote
